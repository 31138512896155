import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

import breakpoints from '../../constants/breakpoints';
import { H3 } from '../../constants/fonts';
import Tab from '../../components/common/components/Tab';

const Wrapper = styled.div`
  position: relative;
  padding: 5vh 0;
  @media (min-width: ${breakpoints.sm}) {
    padding: 15vh 0;
  }
  @media (min-width: ${breakpoints.md}) {
    background-size: 40%;
  }
`;

const ContentWrapper = styled.div`
  text-align: center;
`;
const ContentText = styled.div``;

const Image = styled.img`
  width: 100%;
  padding-top: 2rem;
  @media (min-width: ${breakpoints.md}) {
    max-width: 65%;
  }
`;

const Fleet = ({ data }) => {
  const json = data.allFleetJson.edges;

  const [titleId, setTitleId] = useState(1);

  return (
    <Layout>
      <SEO title="Flota" />
      <Wrapper>
        <Tab json={json} setTitleId={setTitleId} titleId={titleId}>
          {json.map(
            edge => edge.node.item.id === titleId && (
                // TODO: Map only this array which has the exact id
                <div>
                  {edge.node.item.type.map(item => item.map(car => (
                      <ContentWrapper>
                        {car.image && <Image src={car.image} alt={car.title} />}
                        <ContentText>
                          {car.title && <H3>{car.title}</H3>}
                          {car.production && <span>{car.production}</span>}
                          {car.load && <span>{car.load}</span>}
                        </ContentText>
                      </ContentWrapper>
                  )))}
                </div>
            ),
          )}
        </Tab>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFleetJson {
      edges {
        node {
          item {
            title
            id
            type {
              title
              production
              load
              image
            }
          }
        }
      }
    }
  }
`;

export default Fleet;
